import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import tripsReducer from "./features/trips/trips.slice";
import authReducer from "./features/auth/auth.slice";
import userReducer from "./features/user/user.slice";
import companySlice from "./features/company/company.slice";
import clientSlice from "./features/client/client.slice";

const store = configureStore({
  reducer: {
    trips: tripsReducer,
    auth: authReducer,
    user: userReducer,
    company: companySlice,
    client: clientSlice,
  },
});

setupListeners(store.dispatch);

export default store;
