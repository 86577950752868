import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "src/app/config";

const initialState = {
  company: {},

  isAddClientLoading: false,
  isAddClientError: false,
  isAddClientSuccess: false,
  addClientError: null,
};

export const addClient = createAsyncThunk(
  "company/addClient",
  async (thunkAPI) => {
    try {
      const { token, formData } = thunkAPI ?? {};

      if (!formData?.companyId || !formData?.clientName) {
        // console error
        return thunkAPI.rejectWithValue("params missing");
      }

      const response = await axiosApi({ token }).post(
        `/client/addClient`,
        {
          ...formData,
        }
      );
      return JSON.parse(JSON.stringify(response.data));
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    clearSuccess: (state) => {
      //   state.isOnboardCompanyError = false;
      //   state.isOnboardCompanyLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addClient.pending, (state) => {
      state.isAddClientLoading = true;
    });
    builder.addCase(addClient.fulfilled, (state, action) => {
      state.isAddClientLoading = false;
      state.isAddClientSuccess = true;
    });
    builder.addCase(addClient.rejected, (state, action) => {
      state.isAddClientLoading = false;
      state.addClientError = action.payload.error;
      state.isAddClientError = true;
    });
  },
});

export const { clearSuccess } = clientSlice.actions;
export default clientSlice.reducer;
