import React, { useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar";

const LoadingFallback = () => {
  const loadingBarRef = useRef(null);

  useEffect(() => {
    // Start the loading bar when the component mounts
    if (loadingBarRef.current) {
      loadingBarRef.current.continuousStart(); // Start the loading bar with continuous mode
    }

    // Cleanup function to complete the loading bar when the component unmounts
    return () => {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete();
      }
    };
  }, []);

  return (
    <div>
      <LoadingBar color="#f11946" ref={loadingBarRef} height={4} />
    </div>
  );
};

export default LoadingFallback;