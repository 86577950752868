export const localStoreConfig = {
  mobileNumber: "mobileNumber",
  secureToken: "secureToken",
};

export const formModes = {
  create: "create",
  update: "update",
};

export const daysMode = {
  day: 1,
  week: 7,
  month: 30,
};

export const reportXAxisMode = {
  daily: "daily",
  weekly: "weekly",
  monthly: "monthly",
};

export const verificationStatus = {
  PENDING: "PENDING",
  VERIFIED: "VERIFIED",
};
