import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "src/app/config";

const initialState = {
  company: {},
  isOnboardCompanyLoading: false,
  isOnboardCompanyError: false,
  isOnboardCompanySuccess: false,
  onboardCompanyError: null,

  isAddCompanyToUserLoading: false,
  isAddCompanyToUserError: false,
  isAddCompanyToUserSuccess: false,
  addCompanyToUserError: null,
};

export const onboardCompany = createAsyncThunk(
  "company/onboardCompany",
  async (thunkAPI) => {
    try {
      const { token, formData } = thunkAPI ?? {};
      const response = await axiosApi({ token }).post(
        `/company/onboardCompany`,
        {
          ...formData,
        }
      );
      return JSON.parse(JSON.stringify(response.data));
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addCompanyToUser = createAsyncThunk(
  "company/addCompanyToUser",
  async (thunkAPI) => {
    try {
      const { token, formData } = thunkAPI ?? {};
      const response = await axiosApi({ token }).post(
        `/company/addCompanyToUser`,
        {
          ...formData,
        }
      );
      return JSON.parse(JSON.stringify(response.data));
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    clearSuccess: (state) => {
      state.isOnboardCompanySuccess = false;
      state.isOnboardCompanyError = false;
      state.isOnboardCompanyLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onboardCompany.pending, (state) => {
      state.isOnboardCompanyLoading = true;
    });
    builder.addCase(onboardCompany.fulfilled, (state, action) => {
      state.isOnboardCompanyLoading = false;
      state.isOnboardCompanySuccess = true;
    });
    builder.addCase(onboardCompany.rejected, (state, action) => {
      state.isOnboardCompanyLoading = false;
      state.onboardCompanyError = action.payload.error;
      state.isOnboardCompanyError = true;
    });

    builder.addCase(addCompanyToUser.pending, (state) => {
      state.isAddCompanyToUserLoading = true;
    });
    builder.addCase(addCompanyToUser.fulfilled, (state, action) => {
      state.isAddCompanyToUserLoading = false;
      state.isAddCompanyToUserSuccess = true;
    });
    builder.addCase(addCompanyToUser.rejected, (state, action) => {
      state.isAddCompanyToUserLoading = false;
      state.addCompanyToUserError = action.payload.error;
      state.isAddCompanyToUserError = true;
    });
  },
});

export const { clearSuccess } = companySlice.actions;
export default companySlice.reducer;
