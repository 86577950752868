import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApi from "src/app/config";

const initialState = {
  userDetails: {},
  selectedCompanyUser: {},
  companyUserDetails: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: null,

  isAddUserToCompanyLoading: false,
  isAddUserToCompanyError: false,
  isAddUserToCompanySuccess: false,
  addUserToCompanyError: null,
};

export const getCompanyUserDetails = createAsyncThunk(
  "companyUser/fetchCompanyUserList",
  async (thunkAPI) => {
    try {
      const response = await axiosApi({ token: thunkAPI.token }).post(
        `/companyUser/fetchCompanyUserList`
      );
      return JSON.parse(JSON.stringify(response?.data?.data));
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addUserToCompany = createAsyncThunk(
  "user/addUserToCompany",
  async (thunkAPI) => {
    try {
      const { token, formData, companyId } = thunkAPI ?? {};
      const response = await axiosApi({ token }).post(
        `/user/addUserToCompany`,
        {
          companyId,
          ...formData,
        }
      );
      return JSON.parse(JSON.stringify(response.data));
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearSuccess: (state) => {
      state.isSuccess = false;
      state.isError = false;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyUserDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCompanyUserDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;

      const { userDetails, companyUserDetails } = action.payload ?? {};
      let selectedCompanyUser;
      if (userDetails?.selectedCompanyUserId) {
        selectedCompanyUser = companyUserDetails.find(
          (companyUser) =>
            companyUser?.id === userDetails?.selectedCompanyUserId
        );
      } else {
        // send api call to set default companyUser
        selectedCompanyUser = companyUserDetails[0];
      }

      state.userDetails = userDetails;
      state.companyUserDetails = companyUserDetails;
      state.selectedCompanyUser = selectedCompanyUser;
    });
    builder.addCase(getCompanyUserDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
      state.isError = true;
    });

    builder.addCase(addUserToCompany.pending, (state) => {
      state.isAddUserToCompanyLoading = true;
    });
    builder.addCase(addUserToCompany.fulfilled, (state, action) => {
      state.isAddUserToCompanyLoading = false;
      state.isAddUserToCompanySuccess = true;
    });
    builder.addCase(addUserToCompany.rejected, (state, action) => {
      state.isAddUserToCompanyLoading = false;
      state.addUserToCompanyError = action.payload.error;
      state.isAddUserToCompanyError = true;
    });
  },
});

export const { clearSuccess } = userSlice.actions;
export default userSlice.reducer;
