import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosApi from "src/app/config";

const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: null,

  columnFilterData: {},
  isColumnFilterLoading: false,
  isColumnFilterError: false,
  isColumnFilterSuccess: false,
  columnFilterError: null,

  slipMetaDataData: {},
  isSlipMetaDataLoading: false,
  isSlipMetaDataError: false,
  isSlipMetaDataSuccess: false,
  slipMetaDataError: null,

  dashboardOverview: {},
  dashboardOverviewLoading: false,
  dashboardOverviewError: false,
  dashboardOverviewSuccess: false,
  dashboardOverviewErrorMessage: null,

  dashboardProfitReport: [],
  dashboardProfitReportLoading: false,
  dashboardProfitReportError: false,
  dashboardProfitReportSuccess: false,
  dashboardProfitReportErrorMessage: null,

  dashboardHealthReport: [],
  dashboardHealthReportLoading: false,
  dashboardHealthReportError: false,
  dashboardHealthReportSuccess: false,
  dashboardHealthReportErrorMessage: null,
};

export const getTripsData = createAsyncThunk(
  "trips/getTripsData",
  async (thunkAPI) => {
    try {
      const { token, companyId, page, per_page, filters, sorting } =
        thunkAPI ?? {};

      const perPage = parseInt(per_page ?? 0);
      const response = await axiosApi({ token }).post(`/trips/getTripsData`, {
        companyId: companyId,
        filters,
        sorting,
        offset: ((page || 1) - 1) * (perPage ?? 0),
        limit: perPage || 10,
      });
      return JSON.parse(JSON.stringify(response.data));
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTripsMetadata = createAsyncThunk(
  "trips/getTripsMetadata",
  async (thunkAPI) => {
    try {
      const { token, selectedCompanyUser } = thunkAPI;
      if (selectedCompanyUser?.companyId) {
        const response = await axiosApi({ token }).post(
          `/trips/getTripsMetadata`,
          {
            companyId: selectedCompanyUser?.companyId,
          }
        );
        return JSON.parse(JSON.stringify(response.data));
      }
      // TODO: return thunkAPI Reject at all places to handle gracefully
      // return thunkAPI.rejectWithValue(error);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getDashboardTripsOverview = createAsyncThunk(
  "trips/getDashboardTripsOverview",
  async (thunkAPI) => {
    try {
      const { token, companyId, noOfDays } = thunkAPI ?? {};
      const response = await axiosApi({ token }).post(
        `/trips/getDashboardTripsOverview`,
        {
          companyId: companyId,
          noOfDays,
        }
      );
      return JSON.parse(JSON.stringify(response.data));
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getDashboardProfitReport = createAsyncThunk(
  "trips/getDashboardProfitReport",
  async (thunkAPI) => {
    try {
      const { token, companyId, reportXAxisMode } = thunkAPI ?? {};
      const response = await axiosApi({ token }).post(
        `/trips/getDashboardProfitReport`,
        {
          companyId: companyId,
          reportXAxisMode,
        }
      );
      return JSON.parse(JSON.stringify(response.data));
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getDashboardTripHealthReport = createAsyncThunk(
  "trips/getDashboardTripHealthReport",
  async (thunkAPI) => {
    try {
      const { token, companyId, noOfDays } = thunkAPI ?? {};
      const response = await axiosApi({ token }).post(
        `/trips/getDashboardTripHealthReport`,
        {
          companyId: companyId,
          reportMode: noOfDays,
        }
      );
      return JSON.parse(JSON.stringify(response.data));
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createTrip = createAsyncThunk(
  "trips/createTrip",
  async (thunkAPI) => {
    try {
      const formData = thunkAPI.formData;

      if (!formData.companyId || !formData.companyUserId) {
        return thunkAPI.rejectWithValue("params missing");
      }

      const response = await axiosApi({ token: thunkAPI.token }).post(
        `/trips/createTrip`,
        {
          ...formData,
        }
      );
      return JSON.parse(JSON.stringify(response.data));
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateTrip = createAsyncThunk(
  "trips/updateTrip",
  async (thunkAPI) => {
    try {
      const formData = thunkAPI.formData;

      if ((!formData.companyId || !formData.companyUserId) && !formData.id) {
        return thunkAPI.rejectWithValue("params missing");
      }

      const response = await axiosApi({ token: thunkAPI.token }).post(
        `/trips/updateTrip`,
        {
          ...formData,
        }
      );
      return JSON.parse(JSON.stringify(response.data));
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchSlipMetaData = createAsyncThunk(
  "trips/fetchSlipMetaData",
  async (thunkAPI) => {
    try {
      const { token, files } = thunkAPI;

      const formData = new FormData();

      for (const file of files) {
        formData.append("file", file);
      }

      const response = await axiosApi({
        token,
        contentType: "multipart/form-data",
      }).post(`/trips/fetchSlipMetaData`, formData);

      return JSON.parse(JSON.stringify(response.data));
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tripSlice = createSlice({
  name: "trips",
  initialState,
  reducers: {
    clearState: () => initialState,
    clearSuccess: () => (initialState.isSuccess = false),
  },
  extraReducers: (builder) => {
    builder.addCase(getTripsData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTripsData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.data = action.payload?.data;
    });
    builder.addCase(getTripsData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.error;
      state.isError = true;
    });

    builder.addCase(getTripsMetadata.pending, (state) => {
      state.isColumnFilterLoading = true;
    });
    builder.addCase(getTripsMetadata.fulfilled, (state, action) => {
      state.isColumnFilterLoading = false;
      state.isColumnFilterSuccess = true;
      state.columnFilterData = action.payload?.data;
    });
    builder.addCase(getTripsMetadata.rejected, (state, action) => {
      state.isColumnFilterLoading = false;
      state.columnFilterError = action.payload?.error;
      state.isColumnFilterError = false;
    });

    builder.addCase(createTrip.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createTrip.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(createTrip.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.error;
      state.isError = true;
    });

    builder.addCase(updateTrip.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateTrip.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(updateTrip.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.error;
      state.isError = true;
    });

    builder.addCase(getDashboardTripsOverview.pending, (state) => {
      state.dashboardOverviewLoading = true;
    });
    builder.addCase(getDashboardTripsOverview.fulfilled, (state, action) => {
      state.dashboardOverviewLoading = false;
      state.dashboardOverviewSuccess = true;
      state.dashboardOverview = action.payload?.data;
    });
    builder.addCase(getDashboardTripsOverview.rejected, (state, action) => {
      state.dashboardOverviewLoading = false;
      state.dashboardOverviewErrorMessage = action.payload?.error;
      state.dashboardOverviewError = true;
    });

    builder.addCase(getDashboardProfitReport.pending, (state) => {
      state.dashboardProfitReportLoading = true;
    });
    builder.addCase(getDashboardProfitReport.fulfilled, (state, action) => {
      state.dashboardProfitReportLoading = false;
      state.dashboardProfitReportSuccess = true;
      state.dashboardProfitReport = action.payload?.data;
    });
    builder.addCase(getDashboardProfitReport.rejected, (state, action) => {
      state.dashboardProfitReportLoading = false;
      state.dashboardProfitReportErrorMessage = action.payload?.error;
      state.dashboardProfitReportError = true;
    });

    builder.addCase(getDashboardTripHealthReport.pending, (state) => {
      state.dashboardHealthReportLoading = true;
    });
    builder.addCase(getDashboardTripHealthReport.fulfilled, (state, action) => {
      state.dashboardHealthReportLoading = false;
      state.dashboardHealthReportSuccess = true;
      state.dashboardHealthReport = action.payload?.data;
    });
    builder.addCase(getDashboardTripHealthReport.rejected, (state, action) => {
      state.dashboardHealthReportLoading = false;
      state.dashboardHealthReportErrorMessage = action.payload?.error;
      state.dashboardHealthReportError = true;
    });

    builder.addCase(fetchSlipMetaData.pending, (state) => {
      state.isSlipMetaDataLoading = true;
    });
    builder.addCase(fetchSlipMetaData.fulfilled, (state, action) => {
      state.isSlipMetaDataLoading = false;
      state.isSlipMetaDataSuccess = true;
      state.slipMetaDataData = action.payload?.data;
    });
    builder.addCase(fetchSlipMetaData.rejected, (state, action) => {
      state.isSlipMetaDataLoading = false;
      state.slipMetaDataError = action.payload?.error;
      state.isSlipMetaDataError = false;
    });
  },
});

export const { clearState, clearSuccess } = tripSlice.actions;
export default tripSlice.reducer;
